import React from 'react';
import './events.css';
import eventsback from './eventsback.png';
import valo from './valorant.png';
import garena from './garena.png';
import bgmi from './BGMI.png';

export default function Events() {
    return (
        <div>
            <div className='events-container'>
                <img src={eventsback} alt="" className='events-back ' />
                <div className='events-content'>
                    <div className='events-heading anim-fade-left'>EVENTS</div>
                    <div className='events-line1 anim-fade-left'>
                        We will host these major tournaments with a variety of other exciting activities and events.
                    </div>

                    <div className='events-valo event-parent'>
                        <div className="image">
                            <img src={valo} alt="" className='events-valo-img anim-fade-left event-sm' />
                            <div className="content1 content">
                                <p className='hero hero-text'>
                                    Valorant is a 5v5 character-based tactical shooter. Choose from a diverse cast of agents with unique abilities and weapons, and compete on different maps and modes. Whether you prefer stealth, speed, or firepower, Valorant has something for you.
                                </p>
                            </div>
                            <p className='vertical anim-fade-bot'>VALORANT</p>
                        </div>
                    </div>

                    <div className='events-garena event-parent'>
                        <div className="image">
                            <img src={garena} alt="" className='events-garena-img anim-fade-left event-sm' />
                            <div className="content2 content">
                                <p className='hero2 hero-text'>
                                    The Gaming Arena in E-Sports fest Udghosh'23 is a place where you can experience the excitement and thrill of competitive gaming. Experience various esports games, such as BGMI, Valorant, FIFA, and more, and compete with other players from different colleges and regions. The gaming arena is equipped with high-end gaming PCs, consoles, monitors, headphones, and controllers, to ensure a smooth and immersive gaming experience.
                                </p>
                            </div>
                            <p className='vertical anim-fade-bot'>GAMING ARENA</p>
                        </div>
                    </div>

                    <div className='events-bgmi event-parent'>
                        <div className="image">
                            <img src={bgmi} alt="" className='events-bgmi-img anim-fade-right event-sm' />
                            <div className="content3 content">
                                <p className='sidebgm hero-text'>
                                    BGMI is a game battle royale on your mobile device. BGMI is a free-to-play, multiplayer game that lets you compete with up to 100 players in various modes and maps. Choose from different characters with unique skills and weapons, and customize your appearance and loadout. Whether you want to play solo or with your friends, BGMI got you covered.
                                </p>
                            </div>
                            <p className='vertical anim-fade-bot'>BGMI</p>
                        </div>
                    </div>

                    <div className='events-line2'>
                        <div className='events-line2-text anim-fade-left'>
                            FOLLOW US ON INSTAGRAM TO GET NOTIFICATIONS ABOUT UPCOMING MATCHES
                        </div>
                        <div>
                            {/* Wrap the button with an anchor tag that links to the Instagram page */}
                            <a
                                href="https://www.instagram.com/udghosh_iitk/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <button className="rounded-full hover:duration-200 anim-fade-bot follow-button">
                                    Follow Us
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
