import React from 'react'
import './past.css'
import pastbg from './pastevents_bg.png'
import past from './image1.jpg'
import past2 from './1-1.png'
import past3 from './4-1.png'
import past4 from './image2.jpg'
import past5 from './image3.jpg'
// import "react-animated-slider/build/horizontal.css";
import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import withAutoplay from "react-awesome-slider/dist/autoplay";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function Past(){
    return (
        <div className='past-container1' >
            <h2 className='past-heading' style={{
                color: '#665700',
            }}>PAST EVENTS</h2>
            <div className='past-container' >

                {/* <img src={pastbg} alt="" /> */}
                {/* <div className='past-heading'>
                
                // </div> */}
                {/* <div classname="past-body"> */}
                    <div className='past-body-column1'>
                        <img src={past3} className='past-img image1'></img>
                    </div>
                    <div className='past-body-column2'>
                        <div  className='past-body-column2-1'>
                        <img src={past} className='past-img image2'></img>
                        </div>
                        <div className='past-body-column2-2'>
                        <img src={past2} className='past-img image3'></img>
                        </div>
                    </div>
                    <div className='past-body-column3'>
                        <div  className='past-body-column3-1'>
                        <img src={past4} className='past-img image2'></img>
                        </div>
                        <div className='past-body-column3-2'>
                        <img src={past5} className='past-img image3'></img>
                        </div>
                    </div>

                {/* </div> */}
            </div>
            <div className='past-container-mob'>
                <AutoplaySlider
                    play={true}
                    cancelOnInteraction={false}
                    bullets={false}
                    showTimer={false}
                    interval={5000}
                >
                    <div><img src={past3} className='past-img image1'></img></div>
                    <div><img src={past} className='past-img image2'></img></div>
                    <div><img src={past2} className='past-img image3'></img></div>
                    <div><img src={past4} className='past-img image2'></img></div>
                    <div><img src={past5} className='past-img image3'></img></div>

                </AutoplaySlider>
            </div>
        </div>
    );
}