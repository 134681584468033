import React, { useRef, useEffect, useState } from 'react';
import './App.css';
import { Controller } from './components/Controller/Controller';
import Navbar from './components/Navbar/navbar';
import Header from './components/Header/header';
import Events from './components/Events/events';
import Past from './components/past events/past';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Footer from './components/Footer/footer';
import { useMediaQuery } from 'react-responsive';
import { Leftfade, Rightfade, Bottomfade, FadeSrub } from "./components/GsapAnimations/GsapAnimations";
import Teams from './components/Teams/teams';
import Video from './components/Video/Video';

function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  });
  const [lastClicked, setLastClicked] = useState(null);
  const headerRef = useRef(null);
  const navigate = useNavigate();

  const handleHomeClick = () => {
    setLastClicked(new Date().getTime());
    window.scrollTo(0, 0);
  };

  const handleAboutClick = () => {
    setLastClicked(new Date().getTime());
    navigate('/'); 
    setTimeout(() => {
      if (headerRef.current) {
        headerRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100); 
  };

  useEffect(() => {
    Leftfade();
    Rightfade();
    Bottomfade();
    FadeSrub();
  }, [lastClicked]);

  return (
    <div className="App">
      <Routes>
        <Route
          exact
          path="/"
          element={
            <>
              <div id="controller-back" className='controller-class'>
                <div className="nav-container-root">
                  <Navbar onHomeClick={handleHomeClick} onAboutClick={handleAboutClick} />
                </div>
                <Controller />
              </div>

              
              <Header ref={headerRef} />
              <Events />
              <div className='cmnbg'>
                <Past />
                <Video />
              </div>
              <Footer onAboutClick={handleAboutClick} />
            </>
          }
        />
        <Route exact path='/team' element={<><Navbar /><Teams /></>} />
      </Routes>
    </div>
  );
}

export default App;