import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import { OrbitControls } from '@react-three/drei';
import { Canvas, useThree, useLoader } from '@react-three/fiber';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import * as THREE from 'three';
import { TextureLoader } from 'three/src/loaders/TextureLoader';
import { Text } from '@react-three/drei';
import "./style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../Navbar/navbar';
gsap.registerPlugin(ScrollTrigger);

// default markers true for scrolltrigger






export function Model() {
    const angleToRadians = (angleInDeg) => (3.14 / 180) * angleInDeg;
    const controllerRef = useRef(null);
    const planeRef = useRef(null);
    const udghosh3d = useRef(null);
    const esports3d = useRef(null);
    const controller = document.getElementById('webgi-canvas-container');
    const navbar = document.getElementById('navbar');
    const homepage = document.getElementById('controller-back');



    const tex = useLoader(TextureLoader, "image.png");
    const { nodes, materials } = useGLTF('/Black XBOX controller.glb');
    const { scene, camera } = useThree()
    // const { viewport } = useThree()
    // console.log(viewport)
    // useEffect(() => {
    //     console.log(camera);
    //     console.log(camera.position);
    //     console.log(camera.rotation);
    // }, [camera]);



    useEffect(() => {

        const t1 = gsap.timeline();
        t1.to(controllerRef.current.position, {
            x: 0,
            y: -0.5,
            z: 0,
            duration: 4,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: t1,
            trigger: '.controller-class',
            start: "25% 73%",
            end: "30% -60%",
            // markers: true,
            scrub: true,
            onLeave: function (self) {
                let start = self.start;
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })

        const t2 = gsap.timeline();
        t2.to(controllerRef.current.rotation, {
            x: angleToRadians(50),
            y: angleToRadians(-120),
            z: angleToRadians(20),
            duration: 4,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: t2,
            trigger: '.controller-class',
            start: "25% 73%",
            end: "30% -60%",
            // markers: true,
            scrub: true,
            onLeave: function (self) {
                let start = self.start;
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })

        const scrolldownanim = gsap.to(".scroll-down-cont", {
            opacity: 0,
            duration: 0.5,
            paused: true,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            trigger: '.controller-class',
            start: '28% 80%',
            end: '30% top',
            onEnter: () => {
                scrolldownanim.play();
            }
        });

        const udghoshmatanim = gsap.to(udghosh3d.current, {
            strokeOpacity: 1,
            duration: 2,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: udghoshmatanim,
            trigger: '.controller-class',
            start: '40% 50%', 
            end: '50% top',
            scrub: 1,
            onLeave: function (self) {
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })
        const udghoshanim = gsap.to(udghosh3d.current.position, {
            x: 0,
            duration: 2,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: udghoshanim,
            trigger: '.controller-class',
            start: '40% 50%',
            end: '50% top',
            scrub: 1,
            onLeave: function (self) {
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })
        const esportsmatanim = gsap.to(esports3d.current, {
            fillOpacity: 1,
            duration: 2,
            paused: true,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: esportsmatanim,
            trigger: '.controller-class',
            start: '40% 50%',
            end: '50% top',
            scrub: 1,
            onLeave: function (self) {
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })
        const esportsanim = gsap.to(esports3d.current.position, {
            x: 0,
            duration: 2,
            paused: true,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: esportsanim,
            trigger: '.controller-class',
            start: '40% 50%',
            end: '50% top',
            scrub: 1,
            onLeave: function (self) {
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })

        const navbaranim = gsap.to(".nav-container-root", {
            top: 0,
            opacity: 1,
            duration: 2,
            paused: true,
            ease: "Power2.easeOut",
        });
        ScrollTrigger.create({
            animation: navbaranim,
            trigger: '.controller-class',
            start: '40% 50%',
            end: '50% top',
            scrub: 1,
            onLeave: function (self) {
                self.disable();
                self.animation.progress(1);
                ScrollTrigger.refresh();
            }

        })
        ScrollTrigger.create({
            trigger: '.controller-class',
            start: 'bottom bottom',
            end: 'bottom bottom',
            onEnterBack: function (self) {
                homepage.style.height = "100vh" ;
                window.scrollTo({ top: 0, left: 0 });
            }

        })
    });


    //position={[-5, -0.252, 0.293]} rotation={[5, -1.2, 0]} scale={[0.2, 0.2, 0.2]}

    return (
        <group>
            <Text font='fonts\Alfa_Slab_One\AlfaSlabOne-Regular.ttf' letterSpacing={0.05} fontSize={2} position={[-1, 1.7, 0.8]} scale={[0.7, 0.7, 0.7]} ref={udghosh3d} strokeColor={'#CEB865'} strokeWidth={0.03} fillOpacity={0} strokeOpacity={0}>
                UDGHOSH
            </Text>
            <Text font='fonts\Alfa_Slab_One\AlfaSlabOne-Regular.ttf' letterSpacing={0.08} fontSize={2} position={[1, -0.5, 2.5]} scale={[0.4, 0.4, 0.7]} fillOpacity={0} color={'white'} ref={esports3d}>
                ESPORTS
            </Text>
            {/* <Text font='fonts\Alfa_Slab_One\AlfaSlabOne-Regular.ttf' letterSpacing={0.08} fontSize={1} position={[-0.439652, 0.37663799999999975,
                2.242949]} rotation={[-0.16636890530070014, -0.1909535888609253, -0.3]} scale={[0.2, 0.2, 0.2]} fillOpacity={1} color={'white'}>
                Scroll Down Slowly
            </Text> */}
            <mesh position={[0, 0, 0]} rotation={[0, 0, 0]} receiveShadow ref={planeRef}>
                <planeGeometry args={[14, 8]} />
                <meshStandardMaterial map={tex} />
            </mesh>
            <mesh geometry={nodes.node_id30.geometry} material={materials['405']} position={[0, 0, 0]} rotation={[angleToRadians(45), angleToRadians(-90), angleToRadians(0)]} scale={[0.25, 0.25, 0.25]} ref={controllerRef} castShadow />
        </group>
    )
    // position={[0, -0.5, 0]} rotation={[angleToRadians(50), angleToRadians(-120), angleToRadians(20)]} scale={[0.25, 0.25, 0.25]}
}

export const Controller = () => {
    // const aspect = window.innerWidth / window.innerHeight;
    // {{ position: [-0.439652, 0.37663799999999975,2.242949] , rotation:[-0.16636890530070014, -0.1909535888609253, -0.5]}
    return (
        <>
            <div id="webgi-canvas-container">
                <Canvas id='webgi-canvas' camera={{ position: [0, 0, 4.9], rotation: [0, 0, 0] }} shadows>
                    {/* <SheetProvider sheet={getProject('controller').sheet('Scene')}> */}
                    <ambientLight args={["#ffffff", 0.05]} />
                    {/* <OrbitControls/> */}
                    <directionalLight theatreKey="light" args={["#ffffff", 0.5]} position={[0, 4, 5]} castShadow />
                    {/* <directionalLight args={["#ffffff", 2]} position={[0, 5, 5]} /> */}
                    <pointLight theatreKey="yellowlight1" position={[2, 1.5, 1.13144905095180892]} intensity={1.5} color={"#FFD700"} lookAt={[1, 1, 1]} />
                    <pointLight theatreKey="yellowlight2" position={[-1.3, -1.2, 1]} color={"#FFD700"} />
                    <Model />
                    {/* {[2, 1, 2]} */}
                    {/* {[-1, 0, 2]} */}
                    {/* </SheetProvider> */}
                </Canvas>
            </div>
            <div className="scroll-down-cont">
                <h1 className="scroll-icon smoothscroll">
                    Scroll Down
                </h1>
                <FontAwesomeIcon className='font-awesome-arrow' icon={faArrowDown} />
            </div>
        </>
    );
}




useGLTF.preload('/Black XBOX controller.glb')
