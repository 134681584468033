import React from "react";
import { HashLink as Navlink } from "react-router-hash-link";
import "./navbar.css";
import "./nav.css";
import navlogo from "../Navbar/Udghosh_Logo.png";
import { useMediaQuery } from "react-responsive";

export default function Navbar({ onHomeClick, onAboutClick }) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 850px)",
  });

  return (
    <>
      {isDesktopOrLaptop && (
        <nav className="navbar">
          <div id="navbar" className="nav-container">
            <div className="nav-title">
              <img src={navlogo} alt="" />
              UDGHOSH' <span>24</span>
            </div>
            <div className="nav-facility">
              <div className="nav-register">
                <a
                  className="line"
                  href="https://www.battlexo.com/tournaments/64d9e68b1ad9d042fc7b6b00"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p> BGMI Nationals </p>
                </a>
              </div>
              <div className="nav-register">
                <a
                  className="line"
                  href="https://www.battlexo.com/tournaments/64db4e633b5d6f864b5bb3d8"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p> Valorant Nationals </p>
                </a>
              </div>

              <div className="nav-home">
                <Navlink className='nav-item' to="/#top" smooth duration={500} onClick={onHomeClick}>
                  <p className="nav-item-text">Home</p>
                </Navlink>
              </div>

              <div className="nav-about">
                
                <span className='nav-item cursor-pointer' style={{ textDecoration: 'underline', cursor: 'pointer'}} onClick={onAboutClick}>
                  <p className="nav-item-text cursor-pointer">About</p>
                </span>
              </div>

              <div className="nav-team">
                <Navlink className='nav-item' to="/team" smooth duration={500}>
                  <p className="nav-item-text">Teams</p>
                </Navlink>
              </div>
            </div>
          </div>
        </nav>
      )}

      {!isDesktopOrLaptop && (
        <div className="nav">
          <input type="checkbox" id="nav-check" />
          <div className="nav-header">
            <div className="nav-title">
              <img src={navlogo} alt="" />
              UDGHOSH'<span>24</span>
            </div>
          </div>
          <div className="nav-btn">
            <label htmlFor="nav-check">
              <span></span>
              <span></span>
              <span></span>
            </label>
          </div>

          <div className="nav-links">
            <a
              className="line reg"
              href="https://www.battlexo.com/tournaments/64d9e68b1ad9d042fc7b6b00"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p> BGMI Nationals </p>
            </a>
            <a
              className="line reg"
              href="https://www.battlexo.com/tournaments/64db4e633b5d6f864b5bb3d8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <p> Valorant Nationals </p>
            </a>

            <Navlink className='nav-item' to="/#top" smooth duration={500} onClick={onHomeClick}>
              <p className="nav-item-text">Home</p>
            </Navlink>

            
            <span className='nav-item cursor-pointer' onClick={onAboutClick}>
              <p className="nav-item-text cursor-pointer">About</p>
            </span>

            <Navlink className='nav-item' to="/team" smooth duration={500}>
              <p className="nav-item-text">Teams</p>
            </Navlink>
          </div>

          <div className="toggler" id="checkbox"></div>
        </div>
      )}
    </>
  );
}
