import React from "react";
import "./footer.css";
import gun from "./Gun.png";
import upline from "./up-line.png";
import yt from "./YouTube.png";
import fb from "./Facebook.png";
import twit from "./Twitter.png";
import ig from "./Instagram.png";
import li from "./LinkedIn.png";
import home from "./home.png";
import about from "./about.png";
import team from "./team.png";
import downline from "./down-line.png";
import footlogo from "./foot-logo.png";
import { useNavigate } from "react-router-dom";

export default function Footer({ onAboutClick }) {
  const navigate = useNavigate();

  const handleAboutClick = (e) => {
    e.preventDefault();
    navigate('/');
    setTimeout(() => {
      onAboutClick();
    }, 100); // Adjust delay as needed
  };

  return (
    <div>
      <div className="foot-container">
        <div className="foot-upper">
          <img src={gun} alt="" className="foot-gun" />
          <img src={upline} alt="" className="foot-upline" />
          <div className="foot-follow-us">
            <div className="foot-follow">Follow</div>
            <div className="foot-us">US</div>
          </div>
          <div className="foot-social">
            <div className="foot-yt">
              <a href="https://www.youtube.com/@udghoshiitkanpur5681">
                <img src={yt} alt="" className="foot-yt-icon" />
              </a>
              <p className="foot-yt-text">Youtube</p>
            </div>
            <div className="foot-fb">
              <a href="https://www.facebook.com/udghosh.iitk">
                <img src={fb} alt="" className="foot-fb-icon" />
              </a>
              <p className="foot-fb-text">Facebook</p>
            </div>
            <div className="foot-tw">
              <a href="https://twitter.com/udghoshiitk23?t=ka6_8JRSEKRPdEGLoscRBg&s=09">
                <img src={twit} alt="" className="foot-tw-icon" />
              </a>
              <p className="foot-tw-text">Twitter</p>
            </div>
            <div className="foot-ig">
              <a href="https://www.instagram.com/udghosh_iitk/">
                <img src={ig} alt="" className="foot-ig-icon" />
              </a>
              <p className="foot-ig-text">Instagram</p>
            </div>
            <div className="foot-li">
              <a href="https://www.linkedin.com/company/udghosh-iit-kanpur-fest/">
                <img src={li} alt="" className="foot-li-icon" />
              </a>
              <p className="foot-li-text">LinkedIn</p>
            </div>
          </div>
          <div className="foot-pages">
            <div className="foot-pages-title">PAGES</div>
            <div className="foot-home">
              <img src={home} alt="" />
              <a href="/#top" style={{ textDecoration: "none", color: "#d2a6a6" }}>
                <p>HOME</p>
              </a>
            </div>
            <div className="foot-about">
              <img src={about} alt="" />
              <a 
                href="/#para11" 
                style={{ textDecoration: "none", color: "#d2a6a6" }}
                onClick={handleAboutClick}
              >
                <p>ABOUT</p>
              </a>
            </div>
            <div className="foot-team">
              <img src={team} alt="" />
              <a href="/team" style={{ textDecoration: "none", color: "#d2a6a6" }}>
                <p>TEAM</p>
              </a>
            </div>
          </div>
          <div className="foot-lower">
            <img src={downline} alt="" className="foot-downline" />
            <a href="http://udghosh.org.in"> <img src={footlogo} alt="" className="foot-logo" /></a>
            <div className="foot-logo-title">
              <div className="foot-logo-name">UDGHOSH</div>
              <div className="foot-logo-year">
                <span>'24</span>
              </div>
            </div>
            <div className="foot-copy" style={{color:"#d2a6a6", font:"bold"}}>
              ©️ 2024 UDGHOSH '24 All rights reserved
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
