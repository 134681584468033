import React, { forwardRef } from 'react';
import './header.css';
import mainback from '../Header/mainBack.png';
import why from '../Header/WHY.png';
import whyesports from '../Header/whyesports.png';
import player from '../Header/player.png';
import textback from '../Header/text_back.png';

const Header = forwardRef((props, ref) => {
    return (
        <div id='para11' ref={ref}>
            <div className='header-container'>
                <div className='header-film'></div>
                <div className='header-back'>
                    <img src={mainback} alt="" />
                </div>
                <div className='header-why anim-fade-left'>
                    WHY
                </div>
                <div className='header-player anim-fade-bot'>
                    <img src={player} alt="" />
                </div>
                <div className='header-esports anim-fade-right'>
                    ESPORTS ?
                </div>
                <div className='header-text anim-fade-right'>
                    <div className='header-text-image'>
                        <img src={textback} alt="" />
                    </div>
                    <div className='header-text-written'>
                        Udghosh is the annual flagship sports festival organized by IIT Kanpur, which is widely celebrated as one of the largest fest of its kind in India. It's a great way for students to refine their talents while also embracing the ferocious drive and dedication to perfection that define a successful athlete. Udghosh plans many sporting and electronic sports events to bring together the greatest collegiate athletes in the country.
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Header;
