import React from "react";
import "./style.css";

export default function Video() {
  return (
    <>
      {/* <h2 style={{
        textAlign: "left",
        marginLeft: "10px",
        fontSize: "50px",
        fontSmooth: "auto",
        fontWeight: "bold",
        marginTop: "10px",
        color: "#665700",
    }}>BattlexO</h2> */}
      {/* <iframe className="main-video" src="https://drive.google.com/file/d/1pteZKkw55Cc2-2MqgY25pXpIaKSzZ7cE/preview" allow="autoplay" ></iframe> */}
      <video width="100%" controls autoplay poster="/video_thumbnail.png">
        <source
          src="https://live.staticflickr.com/video/53123477203/27230cf35e/1080p.mp4?s=eyJpIjo1MzEyMzQ3NzIwMywiZSI6MTY5MjMwMDM0NiwicyI6ImI2YzM1ZjRiMDQxZTNkNDEyZjg4MTRiMjlmM2YyMjljZTc1NjJkMjgiLCJ2IjoxfQ"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </>
    // https://drive.google.com/file/d/1pteZKkw55Cc2-2MqgY25pXpIaKSzZ7cE/view?usp=sharing
  );
}
